import { getClientInfo } from './clientInfo';
import { ACTION_TYPE, SAFETY_KEY } from './../constant/index';
import { getPageInfo, setPageInfo, IPageInfo } from './pageInfo';
import http from '../utils/http';
import libInfo from './libInfo';
import getNetInfo from './netInfo';
import durationTime from './durationTime';

import { getConfig, IConfig } from './config';
import { getUUID } from '../utils/util';
import { getUserInfo, IUserInfo } from './user';
import { SEND_TYPE } from '../constant/index';
import { ITrackerData, ICleintInfo, ILibInfo } from '../types';
import { isObject } from './../utils/util';
import pick from 'ramda/src/pick';

import { sendInfo } from './exchange';

export interface ILogDataDataItem extends ITrackerData, IPageInfo {
  trackTime: number;
  startTime?: number;
  id: string;
  trackId?: string;
}

export interface ILogData extends ICleintInfo, IUserInfo, ILibInfo {
  customTime: number;
  items: ILogDataDataItem[];
  version: string;
}

const allData: ILogDataDataItem[] = [];
const allDebugData: ILogDataDataItem[] = [];
const allDurationData: ILogDataDataItem[] = [];
let timer: any = null;
// const uuid = getUUID();
// let index = 0;

/**
 * 同步发送
 * @param data
 */
export function send(data: ITrackerData | ILogDataDataItem) {
  const config = getConfig();
  const { sendType } = config;

  if (
    data.actionType === 'PAGE' ||
    data.actionType === 'VIEW' ||
    data.actionType === 'DURATION' ||
    data.actionType === 'VIEW_DURATION'
  ) {
    sendDurationLog(data);
    return;
  }

  if (sendType === SEND_TYPE.SYNC) {
    sendSync(data);
  } else if (sendType === SEND_TYPE.ASYNC) {
    sendAsync(data);
  }
}

// 发送曝光&&时长
export function sendDurationLog(data?: ITrackerData) {
  const config = getConfig();
  if (data) {
    const [newData] = _generateData(data, config);
    if (!newData) {
      return;
    }
    allDurationData.push(newData);
  }
  if (config.sendType === SEND_TYPE.SYNC || data.actionType === 'DURATION' || data.actionType === 'VIEW_DURATION') {
    sendSync();
  } else if (config.sendType === SEND_TYPE.ASYNC) {
    sendAsync();
  }
}

// 立即发送时长
export function sendQuick(data: ILogDataDataItem[]) {
  sendSync(); //立即发送普通日志
  const config = getConfig();
  sendToServer(data, config.durationServerUrl);
}

// 发送时长心跳
export function sendBeat(data: any[]) {
  sendSync(); //立即发送普通日志
  const config = getConfig();
  if (!data.length) {
    return;
  }
  return http(JSON.stringify(data), config.beatServerUrl);
}

export function sendSync(data?: ITrackerData) {
  const config = getConfig();
  if (data) {
    const [newData, debug] = _generateData(data, config);
    if (!newData) {
      return;
    }
    if (debug) {
      allDebugData.push(newData);
    } else {
      allData.push(newData);
    }
  }

  clearTimeout(timer);
  sendToServer(allDurationData, config.durationServerUrl);
  sendToServer(allData);
  sendToServer(allDebugData, config.debugServerUrl);
  allDurationData.length = 0;
  allData.length = 0;
  allDebugData.length = 0;
}

/**
 * 延迟发送  data不存在则马上发送本地allData
 * @param data
 */
export function sendAsync(data?: ITrackerData) {
  const config = getConfig();
  if (data) {
    const [newData, debug] = _generateData(data, config);
    if (!newData) {
      return;
    }
    if (debug) {
      allDebugData.push(newData);
    } else {
      allData.push(newData);
    }
  }
  clearTimeout(timer);
  // 无参数或者大于10条发送发送
  if ((!data && allData.length > 0) || allData.length >= 10 || allDebugData.length >= 10) {
    sendSync();
    return;
  }
  timer = setTimeout(() => {
    sendSync();
  }, config.delayTime);
}

/**
 * 发送到服务器
 * @param data
 * @param isAjax
 */
function sendToServer(data: ILogDataDataItem[], url?: string) {
  if (!data || !data.length) {
    return;
  }

  return http(JSON.stringify(_wrapperData(data)), url);
}

/**
 * 合并其他信息
 * @param data
 */
function _wrapperData(data: ILogDataDataItem[]): ILogData {
  const config = getConfig();
  // index++;

  const wrapperData = {
    customTime: Date.now(),
    items: data,
    ...getClientInfo(),
    ...libInfo,
    ...getUserInfo(),
    version: config.version
  };

  return wrapperData;
}

/**
 * 补充埋点信息
 * @param data
 * @param config
 */
function _generateData(data: ITrackerData, config: IConfig): [ILogDataDataItem, boolean] {
  // index++;

  if (typeof config.beforeGenerateLog === 'function') {
    data = config.beforeGenerateLog(data);
    if (!data) {
      return [null, !!data.debug];
    }
  }

  //序列化自定义
  if (isObject(data.custom)) {
    data.custom = JSON.stringify(data.custom);
  }

  if (typeof data.custom === 'string' && data.custom.length > 15000) {
    data.custom = data.custom.substring(0, 15000) + '[超出15000部分被裁剪]';
  }

  const newData = pick(SAFETY_KEY, data);
  let pageInfo = getPageInfo();
  const netInfo = getNetInfo();

  if (data.actionType === ACTION_TYPE.PAGE) {
    //修改当前pageInfo
    setPageInfo({
      pageId: data.trackId || ''
    });
    // setReferrerId('BEAT');

    // setSourceEventId('UPDATE');
    pageInfo = getPageInfo();
    pageInfo.pageId = null;
  }

  if (data.actionType === ACTION_TYPE.EVENT) {
    // setSourceEventId('RECORD', data);
    pageInfo = getPageInfo();
  }

  const uuid = getUUID();
  const result = {
    ...pageInfo,
    ...newData,
    ...netInfo,

    trackTime: Date.now(),
    id: uuid
  };
  if (!data.debug) {
    (window as any)._TELESCOPE_LOG_ID = uuid;
    if (result.actionType === ACTION_TYPE.PAGE) {
      const durationLogs = durationTime.end();
      if (durationLogs && durationLogs.length) {
        sendQuick(durationLogs);
      }
      durationTime.start(result);
    }

    if (result.actionType === ACTION_TYPE.VIEW) {
      durationTime.start(result);
    }
    sendInfo();

    // console.log(JSON.stringify(result, null, 2));
  }

  return [result, !!data.debug];
}
