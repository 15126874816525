import { getClientInfo } from './clientInfo';
import { getPageInfo } from './pageInfo';

export function sendInfo() {
  let clientInfo = getClientInfo();

  if (clientInfo.appId === 'H5') {
    return;
  }
  // let pageInfo = getPageInfo();
  // let sourceEventId: string = null;

  // try {
  //   const sourceEventInfo = JSON.parse(localStorage.getItem('source_event_id'));

  //   sourceEventId = sourceEventInfo && sourceEventInfo.date + 5000 > Date.now() ? sourceEventInfo.recentId : '';
  // } catch (error) {}

  // const ua = window.navigator.userAgent;
  // if (/miniprogram/i.test(ua)) {
  //   try {
  //     if (window.wx && window.wx.miniProgram) {
  //       window.wx.miniProgram.getEnv(function (res: any) {
  //         if (res.miniprogram) {
  //           window.wx.miniProgram.postMessage({
  //             data: {
  //               type: 'TELESCOPE_INFO',
  //               data: { referrerId: pageInfo.pageId, sourceEventId, channel: clientInfo.channel }
  //             }
  //           });
  //         }
  //       });
  //     } else {
  //       const script = document.createElement('script');
  //       script.type = 'text/javascript';
  //       script.onload = function () {
  //         if (window.wx && window.wx.miniProgram) {
  //           window.wx.miniProgram.getEnv(function (res: any) {
  //             if (res.miniprogram) {
  //               window.wx.miniProgram.postMessage({
  //                 data: {
  //                   type: 'TELESCOPE_INFO',
  //                   data: { referrerId: pageInfo.pageId, sourceEventId, channel: clientInfo.channel }
  //                 }
  //               });
  //             }
  //           });
  //         }
  //       };
  //       script.src = '//static.91jkys.com/attachment/20210316204652605_db936fce9cf540e89ae70226ea953f44.js';
  //       document.body.appendChild(script);
  //     }
  //   } catch (error) {}
  // }

  // if (typeof Sailer !== 'undefined' && /AppInfo|tangyi/.test(ua) && /zhiyun_patient/.test(ua)) {
  //   Sailer.nativeCall('sendEventInfo', {
  //     referrerId: pageInfo.pageId,
  //     channel: clientInfo.channel,
  //     sourceEventId
  //   });
  // }
}
