import { getConfig } from './config';
import { getDomPath,  pause } from 'src/utils/util';
import actionTracker from './actionTracker';

let curAutoPageId='';

export function getPageCode(url: string) {
  let path='';
  let hash=''
  //去除离线H5的本地路径
  if (/file:/.test(url)) {
    const pathMatch=url.match(/(app_flutter|Documents)([^?#].*)/)
    if(pathMatch&&pathMatch[2]){
      path=pathMatch[2]; //todo 抹平path
    }
    const hashMatch=url.match(/#([^?#]*)/)
    if(hashMatch&&pathMatch[1]){
      hash=pathMatch[1];
    }
  }else{
    path=window.location.pathname;
    hash=window.location.hash.replace(/\?.*$/,'');
  }
  //去除路径参数
  if(hash&&typeof hash==='string'){
    hash= hash.replace(/([^/]*\/)\w*\d+[a-zA-Z]+\d+.*$/,'$1param').replace(/\d/g,'').replace('#','').replace(/^\/|\/$/g,'').toLowerCase().trim();
  }
  if(path&&typeof path==='string'){
    path= path.replace(/([^/]*\/)\w*\d+[a-zA-Z]+\d+.*$/,'$1param').replace(/\d/g,'').replace('index.html','').replace(/^\/|\/$/g,'').toLowerCase().trim();
  }
  
  return [path,hash].filter(item=>!!item).join('_').replace(/[/.]/g,'-')
}

function getDomInfo(element:HTMLElement){
  const { autoTrackPrefix } = getConfig();
  let originElement=element;
  const domPath=getDomPath(element);
  const prefix=curAutoPageId?curAutoPageId.replace('autopage-','autoevent-'): `${autoTrackPrefix}-autoevent-${getPageCode(window.location.href)}`;
  
  while (element && element.tagName && element.tagName.toUpperCase() !== 'HTML' && typeof element.textContent==='string') {

    const text=element.textContent.substring(0, 10).trim();
    if(text){
      return  {
        domPath,
        isAutoTrack: true,
        autoTrackId:curAutoPageId,
        trackId:`${prefix}_${getDomPath(element).replace(/>[^>]*[-_]/g,'_').replace(/>/g,'_').replace(/[#.]/g,'')}`,
        eventName:`点击${element.tagName}-${text}(无痕)`
      }
    }

    element = element.parentNode as HTMLElement & HTMLLinkElement;
  }
  
  
  return  {
    domPath,
    isAutoTrack: true,
    autoTrackId:curAutoPageId,
    trackId:prefix+"_"+domPath.replace(/>[^>]*[-_]/g,'_').replace(/[#.]/g,''),
    eventName:`点击${originElement.tagName}(无痕)`
  }
}

function getPageInfo(){
  const { autoTrackPrefix } = getConfig();
  let trackId = `${autoTrackPrefix}-autopage-${getPageCode(window.location.href)}`;
  return {
    trackId,
    eventName: `页面曝光-${window.document.title}(无痕)`,
    isAutoTrack: true
  }
}

export async function watchRouteChange() {
  const {  autoTrackPage } = getConfig();
  if (!autoTrackPage) {
    return;
  }
  let info = getPageInfo();
  if(curAutoPageId===info.trackId){// 延迟300ms或再次获取路由信息
    await pause(300);
    info = getPageInfo();
  }
  curAutoPageId=info.trackId;
  actionTracker.trackAutoPage(info);
}

function triggerTrack(dom: HTMLElement) {
  actionTracker.trackAutoEvent(getDomInfo(dom));
}

export function watchClick() {
  type NewMouseEvent = MouseEvent & {
    path: HTMLLinkElement[] & HTMLInputElement[];
  };

  document.addEventListener(
    'click',
    (e: NewMouseEvent) => {
      let element = e.target as HTMLElement & HTMLLinkElement;
      const { autoTrackClick } = getConfig();

      let hasTrack = false;
      while (element && element.tagName && element.tagName.toUpperCase() !== 'HTML' && element.getAttribute) {
        // const href = element.getAttribute('href');
        // 阻止a标签跳转
        // if (href && element.tagName === 'A' && /\//.test(href) && config.delayLink) {
        //   if (e.preventDefault) {
        //     e.preventDefault();
        //   } else {
        //     window.event.returnValue = true;
        //   }
        // }

        //是否有埋点属性或者交互标签

        if (element.getAttribute('data-track')) {
          actionTracker.trackDom(element);
          hasTrack = true;
        } else if (element._isWatchTrack) {
          hasTrack = true;
        } else if (
          autoTrackClick &&
          (element.tagName === 'A' ||
            element.tagName === 'BUTTON' ||
            element.tagName === 'INPUT' ||
            element.tagName === 'IMG' ||
            element.getAttribute('role'))
        ) {

          triggerTrack(element);
          hasTrack = true;
          break;
        }

        element = element.parentNode as HTMLElement & HTMLLinkElement;
      }
      //如果没有触发任何无痕/有痕埋点 则把当前点击dom进行无痕
      if (!hasTrack && e.target && autoTrackClick) {
        let dom = e.target as HTMLElement & HTMLLinkElement;
        triggerTrack(dom);
      }
    },
    false
  );
}
