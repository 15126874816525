import { IPageInfo } from './pageInfo';

import { ACTION_TYPE } from '../constant';
import { IConfig } from './config';

import { ITrackerData } from 'src/types';
import { getUUID, hashCode } from 'src/utils/util';

interface ILogDataDataItem extends ITrackerData, IPageInfo {
  trackTime: number;
  startTime?: number;
  id: string;
  trackId?: string;
}
class DurationTime {
  static instance: DurationTime = null;
  startTime = Date.now();
  endTime = Date.now();
  config: IConfig = null;
  timeMap: { [prop: string]: any } = {};

  static getInstance() {
    if (!DurationTime.instance) {
      DurationTime.instance = new DurationTime();
    }
    return DurationTime.instance;
  }

  start(info: ILogDataDataItem) {
    if (!info.trackId || this.timeMap[info.trackId]) {
      return;
    }
    this.timeMap[info.trackId + hashCode(JSON.stringify(info.custom))] = {
      startTime: Date.now(),
      ...info
    };
  }

  beat(key?: string | ILogDataDataItem) {
    let logs: any[] = [];
    if (key) {
      if (typeof key !== 'string') {
        key = key.trackId + hashCode(JSON.stringify(key.custom));
      }
      if (this.timeMap[key]) {
        logs.push({
          id: this.timeMap[key].id,
          durationTime: Date.now() - this.timeMap[key].startTime
        });
      }

      return logs;
    }
    for (let key in this.timeMap) {
      if (!this.timeMap[key]) {
        continue;
      }
      logs.push({
        id: this.timeMap[key].id,
        durationTime: Date.now() - this.timeMap[key].startTime
      });
    }
    return logs;
  }

  end(key?: string | ILogDataDataItem) {
    let logs: ILogDataDataItem[] = [];
    if (key) {
      if (typeof key !== 'string') {
        key = key.trackId + hashCode(JSON.stringify(key.custom));
      }
      if (this.timeMap[key]) {
        logs.push(this.generateLog(this.timeMap[key]));
        this.timeMap[key] = null;
      }

      return logs;
    }

    for (let key in this.timeMap) {
      if (!this.timeMap[key]) {
        continue;
      }
      logs.push(this.generateLog(this.timeMap[key]));
      this.timeMap[key] = null;
    }

    return logs;
  }

  generateLog(info: ILogDataDataItem) {
    const log = {
      ...info,
      actionType: info.actionType === ACTION_TYPE.PAGE ? ACTION_TYPE.DURATION : ACTION_TYPE.VIEW_DURATION,
      durationTime: Date.now() - info.startTime,
      trackTime: Date.now(),
      masterId: info.id,
      trackId: '',
      pageId: info.actionType === ACTION_TYPE.PAGE ? info.trackId : info.pageId,
      id: getUUID()
    };

    return log;
  }
}

let instance = DurationTime.getInstance();
export default instance;
