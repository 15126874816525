import { getConfig } from 'src/core/config';
import { getCookie, getQueryVariable, inMin, setCookie, inWechat, getUUID } from './../utils/util';

function getDeviceId() {
  const config = getConfig();
  let deviceId =
    getCookie(config.deviceIdKey) ||
    localStorage.getItem(config.deviceIdKey) ||
    (config.localGenerateDeviceId ? getUUID() : null);
  if (deviceId) {
    saveDeviceId(deviceId);
  }
  return deviceId;
}

function saveDeviceId(deviceId: string) {
  const config = getConfig();
  setCookie(config.deviceIdKey, deviceId);
  localStorage.setItem(config.deviceIdKey, deviceId);
}

export interface IClientInfo {
  clientWidth: number;
  clientHeight: number;
  radio: number;
  domain: string;
  appId: string;
  appVersion: string;
  appType: string;
  marketId?: string;
  sessionId: string;
  channel?: string;
  deviceId?: string;
}

let clientInfo: IClientInfo = {
  clientWidth: window.screen.height,
  clientHeight: window.screen.width,
  radio: window.devicePixelRatio || 1,
  domain: document.domain || '',
  appId: 'H5',
  appVersion: null,
  appType: 'H5',
  marketId: null,
  sessionId: getUUID(),
  channel: null,
  deviceId: getDeviceId()
};

const ua = window.navigator.userAgent;
//获取客户端deviceId
if (/DeviceId/i.test(ua)) {
  const deviceIdMatch = ua.match(/DeviceId\((.*?)\)/);
  if (deviceIdMatch && deviceIdMatch.length >= 2) {
    clientInfo.deviceId = deviceIdMatch[1];
  }
}

//获取appID 和app版本
if (/AppInfo/i.test(ua)) {
  const AppInfoMatch = ua.match(/AppInfo\((.*?);(.*?)\)/);
  if (AppInfoMatch && AppInfoMatch.length >= 3) {
    (clientInfo.appId = AppInfoMatch[1]), (clientInfo.appVersion = AppInfoMatch[2]), (clientInfo.appType = 'APP');
  }
}

export const setClientInfo = (info: Partial<IClientInfo>) => {
  let urlInfo: Partial<IClientInfo> = {};

  //小程序参数带入
  let urlInfoStr = getQueryVariable('telescope-info');
  if (urlInfoStr) {
    try {
      urlInfo = JSON.parse(decodeURIComponent(urlInfoStr));
    } catch (error) {}
  }

  // 本地 缓存
  let cookieInfoStr = window.localStorage.getItem('TELESCOPE_CACHE_INFO');
  let cookieInfo: Partial<IClientInfo> & { expired?: number } = {};
  if (cookieInfoStr) {
    try {
      cookieInfo = JSON.parse(cookieInfoStr);
    } catch (error) {}
  }

  if (cookieInfo.expired && cookieInfo.expired < Date.now()) {
    // 30分钟过期
    cookieInfo.channel = null;
    cookieInfo.sessionId = getUUID();
  }
  delete cookieInfo.expired;

  const channel = getQueryVariable('channel') || urlInfo.channel || cookieInfo.channel || null;
  clientInfo = {
    ...clientInfo,
    ...cookieInfo,
    ...urlInfo,
    channel,
    ...info
  };

  if (inMin) {
    clientInfo.appType = 'MINI';
  } else if (inWechat) {
    clientInfo.appId = 'wechat';
    clientInfo.appType = 'WECHAT';
  }
  // 兼容小程序ua不准 没法判断是否是小程序
  if (/_mini_/.test(clientInfo.appId)) {
    clientInfo.appType = 'MINI';
  }

  //传入deviceId 则覆盖本地deviceId缓存
  if (clientInfo.deviceId) {
    saveDeviceId(clientInfo.deviceId);
  }

  const cacheStr = JSON.stringify({
    appId: clientInfo.appId,
    appVersion: clientInfo.appVersion,
    marketId: clientInfo.marketId,
    channel: clientInfo.channel,
    sessionId: clientInfo.sessionId,
    deviceId: clientInfo.deviceId,
    expired: Date.now() + 30 * 60 * 1000
  });

  window.localStorage.setItem('TELESCOPE_CACHE_INFO', cacheStr);
};

export const getClientInfo = () => {
  setClientInfo({});
  return { ...clientInfo };
};
