import imagesLoaded from '@zyf2e/imagesloaded';
import actionTracker from './actionTracker';

/**
 *
 * @returns 获取页面性能指标
 */
const getPageNav = () => {
  try {
    const pageNav = performance.getEntriesByType('navigation')[0].toJSON();
    return {
      //阶段指标
      unload: Math.round(pageNav.unloadEventEnd - pageNav.unloadEventStart), //前一个页面卸载耗时
      redirect: Math.round(pageNav.redirectEnd - pageNav.redirectStart), //重定向耗时
      appCache: Math.round(pageNav.domainLookupStart - pageNav.fetchStart), //缓存耗时
      dns: Math.round(pageNav.domainLookupEnd - pageNav.domainLookupStart), //DNS 解析耗时
      tcp: Math.round(pageNav.connectEnd - pageNav.connectStart), //TCP 连接耗时
      ssl: Math.round(pageNav.connectEnd - pageNav.secureConnectionStart), //SSL 安全连接耗时
      ttfb: Math.round(pageNav.responseStart - pageNav.requestStart), //Time to First Byte（TTFB），网络请求耗时
      response: Math.round(pageNav.responseEnd - pageNav.responseStart), //数据传输耗时
      dom: Math.round(pageNav.domInteractive - pageNav.responseEnd), //可交互 DOM 解析耗时
      dom2: Math.round(pageNav.domContentLoadedEventStart - pageNav.domInteractive), //剩余 DOM 解析耗时
      DCL: Math.round(pageNav.domContentLoadedEventEnd - pageNav.domContentLoadedEventStart), //DOMContentLoaded 事件耗时
      resources: Math.round(pageNav.loadEventStart - pageNav.domContentLoadedEventEnd), //资源加载耗时
      onLoad: Math.round(pageNav.loadEventEnd - pageNav.loadEventStart), //onLoad事件耗时

      //关键指标
      firstbyte: Math.round(pageNav.responseStart - pageNav.domainLookupStart), //首包时间
      fpt: Math.round(pageNav.responseEnd - pageNav.fetchStart), //首次渲染时间
      tti: Math.round(pageNav.domInteractive - pageNav.fetchStart), //完成 DOM 构建
      ready: Math.round(pageNav.domContentLoadedEventEnd - pageNav.fetchStart), //DOM Ready
      load: Math.round(pageNav.loadEventStart - pageNav.fetchStart), //页面完全加载时间
      act: 0,
      cct: 0,
      apiNum: 0,
      ast: 0,
      att: 0
    };
  } catch (error) {
    return null;
  }
};

const getResource = () => {
  try {
    const sources = performance
      .getEntriesByType('resource')
      .filter((item) => (item.duration > 200 || item['initiatorType'] === 'xmlhttprequest') && !/log/.test(item.name))
      .reduce((total, item) => {
        total[item.name.replace(/^.*?\.(com|cn|vip|net)/, '').replace(/\?.*$/, '')] = Math.round(item.duration);
        return total;
      }, {});

    return sources;
  } catch (error) {
    return null;
  }
};

export const initPerformance = () => {
  //劫持xhr
  // injectedXHR();

  // 接口完成请求 和 window.onload 执行顺序不确定
  let isListening = false;
  let isLoaded = false;
  let isSend = false;

  let isFirstApi = true;

  const openApiList = [] as string[];
  const successApiList = [] as string[];
  let url = window.location.href;

  let timer: number = null;

  function removeListerner() {
    window.removeEventListener('xhrOpen', onOpen);
    window.removeEventListener('xhrFinish', onSuccess);
  }

  function onOpen(e: any) {
    isListening = true;
    if (isFirstApi) {
      isFirstApi = false;
      try {
        performance.mark('ending_ast');
      } catch (error) {}
    }
    openApiList.push(e.detail.url);
    if (openApiList.length > 100) {
      removeListerner();
    }
  }
  function onSuccess(e: any) {
    successApiList.push(
      e.detail.url.replace(/^.*?\.(com|cn|vip|net)/, '').replace(/\?.*$/, '') + ':' + e.detail.duration
    );

    // 100ms 无进行中的请求 则初始化接口全部完成
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (successApiList.length === openApiList.length) {
        try {
          removeListerner();
          performance.mark('ending_act');
          imagesLoaded(window.document.body, { background: '.bg' }, function (a) {
            performance.mark('ending_cct');
            isListening = false;
            // window load 在前面
            if (isLoaded && !isSend) {
              isSend = true;
              finishListening();
            }
          });
        } catch (error) {}
      }
    }, 100);
  }
  window.addEventListener('xhrOpen', onOpen);
  window.addEventListener('xhrFinish', onSuccess);

  function onLoad() {
    try {
      url = window.location.href;

      imagesLoaded(window.document.body, { background: '.bg' }, function () {
        performance.mark('ending_cct');

        isLoaded = true;
        //延迟1000ms 后判断是否有请求监听 无则上报
        setTimeout(() => {
          if (!isListening && !isSend) {
            isSend = true;
            removeListerner();
            finishListening();
          }
        }, 1000);
      });
    } catch (e) {}
  }

  if (document.readyState === 'complete') {
    onLoad();
  } else {
    window.addEventListener('load', onLoad);
  }

  function finishListening() {
    //监听期间页面如果发送跳转 则弃用性能数据
    if (url !== window.location.href) {
      return;
    }

    //hash路由不同path就是不同项目  history路由则不通域名不通项目
    const key = /^#\//.test(window.location.hash) ? 'isFirstLoad__' + window.location.pathname : 'isFirstLoad';
    const cache = window.localStorage.getItem(key);

    //是否第一次访问
    const isFirstLoad = !cache;

    const cacheTime = cache ? Date.now() - Number(cache) : null;

    window.localStorage.setItem(key, String(Date.now()));

    const data = getPageNav();
    if (!data) {
      return;
    }

    try {
      const cctMeasure = performance.measure('multiply_measure', 'fetchStart', 'ending_cct');
      //@ts-ignore
      if (!!cctMeasure) {
        //@ts-ignore
        data.cct = Math.round(cctMeasure.duration);
      }
    } catch (error) {}

    try {
      const actMeasure = performance.measure('multiply_measure', 'fetchStart', 'ending_act');
      //@ts-ignore
      if (!!actMeasure) {
        //@ts-ignore
        data.act = Math.round(actMeasure.duration);
      }
    } catch (error) {}

    try {
      const astMeasure = performance.measure('multiply_measure', 'fetchStart', 'ending_ast');
      //@ts-ignore
      if (!!astMeasure) {
        //@ts-ignore
        data.ast = Math.round(astMeasure.duration);
      }
    } catch (error) {}

    //无效数据
    if (data.load <= 0 || data.cct <= 0) {
      return;
    }

    if (data.ast === 0) {
      data.ast = data.cct;
    }

    if (data.act === 0) {
      data.act = data.cct;
    }

    data.att = data.act - data.ast;
    data.apiNum = successApiList.length;
    //资源加载日志
    const sources = getResource();

    function sendLog() {
      //性能指标日志
      actionTracker.trackLog({
        trackId: isFirstLoad ? 'performance-index-first-log' : 'performance-index-log',
        custom: data,
        durationTime: cacheTime,
        supportedAbi: JSON.stringify(successApiList)
      });

      if (sources && Object.keys(sources).length > 0) {
        actionTracker.trackLog({
          trackId: isFirstLoad ? 'performance-resource-first-log' : 'performance-resource-log',
          custom: sources,
          durationTime: cacheTime,
          supportedAbi: JSON.stringify(successApiList)
        });
      }
    }
    if (typeof (window as any).requestIdleCallback === 'function') {
      (window as any).requestIdleCallback(sendLog);
    } else {
      sendLog();
    }
  }
};
