import { ACTION_TYPE } from './../constant/index';
import { getQueryVariable } from 'src/utils/util';

export interface IPageInfo {
  pageId?: string;
  url: string;
  title: string;
  host: string;
  path: string;
  hash: string;
}

// export const getReferrerId = () => {
//   let urlInfoStr = getQueryVariable('telescope-info');

//   let urlInfo = {} as {
//     referrerId: string;
//   };
//   if (urlInfoStr) {
//     try {
//       urlInfo = JSON.parse(decodeURIComponent(urlInfoStr));
//       return urlInfo.referrerId;
//     } catch (error) {}
//   }

//   try {
//     let referrerId = getQueryVariable('referrer_id');
//     if (referrerId) {
//       return referrerId;
//     } else {
//       const referrerInfo = JSON.parse(localStorage.getItem('referrer_id'));
//       return referrerInfo ? referrerInfo.referrerId : null;
//     }
//   } catch (error) {
//     return null;
//   }
// };

// export const setReferrerId = (type: 'BEAT' | 'UPDATE') => {
//   try {
//     const pageInfo = getPageInfo();
//     let referrerInfo: {
//       pageId: string;
//       referrerId: string;
//       date: number;
//     } = JSON.parse(localStorage.getItem('referrer_id')) || {
//       pageId: pageInfo.pageId,
//       referrerId: '',
//       date: Date.now()
//     };

//     switch (type) {
//       case 'UPDATE': //置空
//         referrerInfo.date = Date.now();
//         referrerInfo.referrerId = referrerInfo.date + 5000 > Date.now() ? referrerInfo.pageId : '';
//         referrerInfo.pageId = '';

//         break;
//       case 'BEAT': //
//         referrerInfo.pageId = pageInfo.pageId;
//         referrerInfo.date = Date.now();
//         break;
//     }
//     localStorage.setItem('referrer_id', JSON.stringify(referrerInfo));
//     setPageInfo({});
//   } catch (error) {}
// };

// export const getSourceEventId = () => {
//   let urlInfoStr = getQueryVariable('telescope-info');

//   let urlInfo = {} as {
//     sourceEventId: string;
//   };
//   if (urlInfoStr) {
//     try {
//       urlInfo = JSON.parse(decodeURIComponent(urlInfoStr));
//       return urlInfo.sourceEventId;
//     } catch (error) {}
//   }

//   try {
//     const sourceEventInfo = JSON.parse(localStorage.getItem('source_event_id'));
//     if (sourceEventInfo.date + 5000 > Date.now()) {
//       return sourceEventInfo.sourceEventId;
//     }
//     return null;
//   } catch (e) {
//     return null;
//   }
// };

// export const setSourceEventId = (type: 'RECORD' | 'UPDATE', data?: any) => {
//   let preSourceEventInfo: {
//     date: number;
//     recentId: string;
//     sourceEventId: string;
//     isAutoTrack: boolean;
//     url: string;
//   } = null;

//   try {
//     preSourceEventInfo = JSON.parse(localStorage.getItem('source_event_id'));

//     switch (type) {
//       case 'UPDATE': //置空
//         preSourceEventInfo = {
//           sourceEventId:
//             preSourceEventInfo && preSourceEventInfo.date + 5000 > Date.now() ? preSourceEventInfo.recentId : '',
//           recentId: (preSourceEventInfo && preSourceEventInfo.recentId) || '',
//           date: Date.now(),

//           isAutoTrack: false,
//           url: location.href
//         };
//         localStorage.setItem('source_event_id', JSON.stringify(preSourceEventInfo));

//         break;
//       case 'RECORD': //记录
//         if (data && data.actionType === ACTION_TYPE.EVENT && !data.debug) {
//           if (!preSourceEventInfo || preSourceEventInfo.isAutoTrack || preSourceEventInfo.date < Date.now() - 500) {
//             localStorage.setItem(
//               'source_event_id',
//               JSON.stringify({
//                 date: Date.now(),
//                 isAutoTrack: data.isAutoTrack,
//                 url: location.href,
//                 sourceEventId: preSourceEventInfo ? preSourceEventInfo.sourceEventId : '',
//                 recentId: data.trackId
//               })
//             );
//           }
//         }
//         break;
//     }
//     setPageInfo({});
//   } catch (error) {}
// };

let pageInfo: IPageInfo = {
  pageId: null,
  // referrerId: getReferrerId(),
  // sourceEventId: getSourceEventId(),
  // referrerUrl: document.referrer || '',
  url: location.href,
  host: location.host,
  path: location.pathname,
  hash: location.hash,
  title: document.title || ''
};

export const setPageInfo = (info: Partial<IPageInfo>) => {
  // if (info.sourceEventId) {
  //   localStorage.setItem(
  //     'source_event_id',
  //     JSON.stringify({
  //       date: Date.now(),
  //       isAutoTrack: false,
  //       url: '',
  //       sourceEventId: info.sourceEventId,
  //       recentId: info.sourceEventId
  //     })
  //   );
  // }
  // if (info.referrerId) {
  //   localStorage.setItem(
  //     'referrer_id',
  //     JSON.stringify({
  //       pageId: info.referrerId,
  //       referrerId: info.referrerId,
  //       date: Date.now()
  //     })
  //   );
  // }
  pageInfo = {
    ...pageInfo,
    url: location.href,
    host: location.host,
    path: location.pathname,
    hash: location.hash,
    title: document.title || '',
    ...info
  };
};

export const getPageInfo = () => {
  setPageInfo({});
  return { ...pageInfo };
};
