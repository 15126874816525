import Base64 from './base64';
import { getConfig } from 'src/core/config';

export default function http(data: string, url?: string, cb = () => {}) {
  const dataStr = data;
  const config = getConfig();

  url = `${url || config.serverUrl}?${
    config.trackKey ? `trackKey=${config.trackKey}&k=${config.trackKey}&` : 'deb=true&'
  }time=${Date.now()}`;

  //使用sendBeacon 出现重复提交
  if (
    window.location.protocol === 'https:' &&
    typeof window.navigator.sendBeacon === 'function' &&
    typeof Blob === 'function'
  ) {
    const headers = {
      type: 'text/plain; charset=UTF-8'
    };
    const blob = new Blob([dataStr], headers);
    const success = window.navigator.sendBeacon(url, blob);
    if (success) {
      cb();
      return;
    }
  }

  if (dataStr.length > 2000) {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.addEventListener('readystatechange', function () {
      if (this.readyState === 4) {
        cb();
      }
    });
    xhr.open('POST', url, true);
    xhr.setRequestHeader('Content-Type', 'text/plain;charset=UTF-8');
    xhr.withCredentials = true;
    xhr.send(dataStr);
  } else {
    const img: HTMLImageElement = new Image();
    img.onload = () => {
      cb();
    };
    img.src = `${url}&data=${encodeURIComponent(dataStr)}`;
  }
}
