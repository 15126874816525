import { getPageInfo, setPageInfo } from './pageInfo';

import actionTracker from './actionTracker';
import durationTime from './durationTime';
import { getConfig, setConfig, IConfig } from './config';
import { sendBeat, sendQuick } from './send';
import hijackHistoryEvent from '../utils/hijackHistoryEvent';
import { getFlag, setFlag } from '../utils/util';
import { setClientInfo } from './clientInfo';
import injectedXHR from '..//utils/injectedXHR';

import { login } from './user';
import { initPerformance } from './performance';
import { watchClick, watchRouteChange } from './autoTrack';
import { initTrycatch } from './trycatch';

function immediatelyInjecte() {
  if (getFlag('injected')) return;
  setFlag('injected');
  // 立即执行 防止页面重定向 参数丢失
  setClientInfo({});
  //劫持xhr
  injectedXHR();

  //注入history事件
  hijackHistoryEvent();

  // 立即监听
  initTrycatch();

  //性能监控
  initPerformance();
}
immediatelyInjecte();

const install = function (conf?: Partial<IConfig>) {
  if (getFlag('install')) return;
  setFlag('install');
  if (conf) {
    setConfig(conf);
  }

  const config = getConfig();
  if (!config.version && (window as any).version && typeof (window as any).version === 'string') {
    setConfig({ version: (window as any).version });
  }

  //自动埋页面;
  routeChange();

  watchClick();
  function routeChange() {
    login(); //每次路由编号就获取用户信息 兼容中途登录

    watchRouteChange();

    const config = getConfig();
    if (config.routeChangeAfter) {
      config.routeChangeAfter();
    }
  }

  // 单页面应用routerchange
  if (typeof window.onpopstate === 'undefined') {
    window.addEventListener('hashchange', routeChange);
  }
  window.addEventListener('historyPushState', routeChange);
  window.addEventListener('historyPopstate', routeChange);

  window.addEventListener('visibilitychange', () => {
    var isHidden = document.hidden;
    var pageInfo = getPageInfo();
    if (isHidden) {
      const logs = durationTime.end();
      sendQuick(logs);
    } else {
      actionTracker.trackPage({ trackId: pageInfo.pageId });
    }
  });

  // onbeforeunload 和 onunload 都触发发送

  const onLeave = (() => {
    let sended = false;
    return () => {
      if (sended) {
        return;
      }
      const logs = durationTime.end();
      sendQuick(logs);
      sended = true;
    };
  })();

  const beatFn = () => {
    // setReferrerId('BEAT');
    const config = getConfig();
    if (config.sendBeat) {
      const logs = durationTime.beat();
      sendBeat(logs); //todo
    }
    setTimeout(beatFn, 3000);
  };
  setTimeout(beatFn, 3000);

  window.addEventListener('beforeunload', onLeave);
  window.addEventListener('unload', onLeave);
};

//自动install
const ua = window.navigator.userAgent;
if (/AppInfo|tangyi/.test(ua)) {
  let isReady = false; // 处理sailerready 执行两次问题
  const appInit = () => {
    Sailer.ready(() => {
      if (isReady) {
        return;
      }
      isReady = true;
      // 获取userId
      const { uid } = Sailer.getUserInfo();
      if (uid > 0) {
        login({ uid });
      }

      install();

      Sailer.nativeCall('getEventInfo', (res: any) => {
        if (!res || !res.data) {
          return;
        }
        const eventInfo = res.data;
        if (eventInfo && eventInfo.items && eventInfo.items.length) {
          setClientInfo({
            channel: eventInfo.items[0].channel,
            deviceId: eventInfo.deviceId,
            appId: eventInfo.appId,
            appVersion: eventInfo.version,
            sessionId: eventInfo.items[0].sessionId
          });
        }
      });
    });
  };

  if (typeof Sailer !== 'undefined') {
    appInit();
  } else {
    //兼容sdk引入顺序错误
    setTimeout(() => {
      if (typeof Sailer !== 'undefined') {
        appInit();
      } else {
        install();
      }
    }, 0);
  }
} else {
  window.addEventListener('DOMContentLoaded', () => {
    const config = getConfig();
    if (config.autoInstall) {
      install();
    }
  });
}

export default install;
