import { send, sendAsync, sendQuick } from './send';
import { ACTION_TYPE } from '../constant';
import { getDomPath } from '../utils/util';
import { ITrackerData, VisSenseConfig } from '../types';

import durationTime from './durationTime';
import { getConfig } from './config';

import VisSense from './viewTracker';
import { setPageInfo } from './pageInfo';

/**
 *埋点入口类
 *
 * @class ActionTracker
 */
class ActionTracker {
  static instance: ActionTracker = null;
  static getInstance() {
    if (!ActionTracker.instance) {
      ActionTracker.instance = new ActionTracker();
    }
    return ActionTracker.instance;
  }
  constructor() {
    this.trackEvent = this.trackEvent.bind(this);
    this.trackPage = this.trackPage.bind(this);
    this.trackViewStart = this.trackViewStart.bind(this);
    this.trackView = this.trackView.bind(this);
    this.trackViewEnd = this.trackViewEnd.bind(this);
    this.trackLog = this.trackLog.bind(this);
  }

  /**
   * 埋点页面,
   * @memberof ActionTracker
   */
  trackPage(info: ITrackerData = {}) {
    let data: ITrackerData = {
      actionType: ACTION_TYPE.PAGE,
      ...info
    };
    data.pageId = data.trackId;

    setPageInfo({ pageId: data.pageId });
    send(data);
  }

  /**
   *
   * 事件埋点
   */
  trackEvent(info: ITrackerData = {}) {
    let data: ITrackerData = {
      actionType: ACTION_TYPE.EVENT,
      eventName: '点击',
      ...info
    };
    send(data);
  }

  /**
   *
   * 事件埋点
   */
  trackAutoEvent(info: ITrackerData = {}) {
    let data: ITrackerData = {
      actionType: ACTION_TYPE.AUTO_EVENT,
      eventName: '无痕点击',
      ...info
    };
    send(data);
  }

  /**
   *
   * 事件埋点
   */
  trackAutoPage(info: ITrackerData = {}) {
    let data: ITrackerData = {
      actionType: ACTION_TYPE.AUTO_PAGE,
      eventName: '无痕曝光',
      ...info
    };
    send(data);
  }

  /**
   * 视窗埋点 暂时不启用
   * @param dom
   * @param info
   * @param visSenseConfig
   */
  trackView(dom: HTMLElement, info: ITrackerData, visSenseConfig: VisSenseConfig = {}) {
    if (dom) {
      let data: ITrackerData = {
        actionType: ACTION_TYPE.VIEW,
        domId: dom.id,
        domClass: dom.className,
        domTag: dom.tagName,
        domContent: dom.textContent.substring(0, 20).trim(),
        domPath: getDomPath(dom),
        ...info
      };

      var visobj = VisSense(dom);
      visobj.onPercentageTimeTestPassed(
        function () {
          send(data);
        },
        {
          percentageLimit: visSenseConfig.percentageLimit || 1,
          timeLimit: visSenseConfig.timeLimit || 0,
          interval: 200
        }
      );
    }
  }

  trackViewStart(info: ITrackerData) {
    send({ ...info, actionType: ACTION_TYPE.VIEW });
  }

  trackViewEnd(info: ITrackerData) {
    const logs = durationTime.end(info as any);
    sendQuick(logs);
  }

  /**
   *通用埋点入口 根据埋点类型调用
   *
   */
  track(info: ITrackerData) {
    switch (info.actionType) {
      case ACTION_TYPE.EVENT:
        this.trackEvent(info);
        break;
      case ACTION_TYPE.PAGE:
        this.trackPage(info);
        break;
      default:
        break;
    }
    send(info);
  }

  /**
   * a标签埋点 做300毫秒延迟跳转 确保埋点成功 (暂时注销)
   * @param linkDom
   * @param info
   */
  trackLink(linkDom: HTMLLinkElement, info: ITrackerData = {}) {
    const config = getConfig();
    var url = linkDom.getAttribute('href');
    if (config.delayLink && url && /\//.test(url)) {
      setTimeout(() => {
        if (linkDom.getAttribute('target') === '_blank') {
          window.open(url);
        } else {
          window.location.href = url;
        }
      }, config.delayLinkTime);
    }

    this.trackDom(linkDom, info);
    sendAsync();
  }

  /**
   *埋点dom 做1秒防抖
   *
   * @param {(HTMLLinkElement | HTMLInputElement | HTMLLinkElement)} dom
   * @param {ITrackerData} [info={}]
   * @returns
   * @memberof ActionTracker
   */
  trackDom(dom: HTMLLinkElement | HTMLInputElement | HTMLLinkElement, info: ITrackerData = {}) {
    //防止频繁触发
    const _dom: any = dom;
    if (_dom.IS_TRACKED) {
      clearTimeout(_dom.DELAY_TRACK_TIME);
      _dom.DELAY_TRACK_TIME = setTimeout(() => {
        _dom.IS_TRACKED = false;
      }, 1000);
      return;
    }
    _dom.IS_TRACKED = true;
    _dom.DELAY_TRACK_TIME = setTimeout(() => {
      _dom.IS_TRACKED = false;
    }, 1000);

    let domPath = getDomPath(dom);
    let trackInfo = {
      trackId: '',
      domPath,
      domContent: dom.textContent.substring(0, 20).trim(),
      domHref: (dom as HTMLLinkElement).href || (dom as unknown as HTMLImageElement).src || null
    };

    let track = dom.getAttribute('data-track');

    if (track && track.search(/^\{[\S\s]*\}$/) >= 0) {
      trackInfo = { ...trackInfo, ...JSON.parse(track) };
    } else {
      trackInfo.trackId = track || '';
    }
    if (info) {
      trackInfo = { ...trackInfo, ...info };
    }
    this.trackEvent(trackInfo);
  }

  /**
   * 前端日志收集
   * @memberof ActionTracker
   */
  trackLog(info: ITrackerData = {}) {
    let data: ITrackerData = {
      actionType: ACTION_TYPE.EVENT,
      eventName: '前端日志',
      debug: true,
      ...info
    };

    send(data);
  }
}

let instance = ActionTracker.getInstance();

export default instance;
