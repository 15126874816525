import { setFlag, getFlag } from './util';

export default function () {
  if (getFlag('XHRInjected')) return;
  setFlag('XHRInjected', true);
  function xhrEventTrigger(event: string, data: any) {
    const xhrEvent = new CustomEvent(event, { detail: data });
    window.dispatchEvent(xhrEvent);
  }
  const oldOpen = XMLHttpRequest.prototype.open;
  const oldSend = XMLHttpRequest.prototype.send;
  XMLHttpRequest.prototype.open = function () {
    this.url = arguments[1];
    this.startTime = Date.now();
    this.method = arguments[0];
    oldOpen.apply(this, arguments);
  };

  XMLHttpRequest.prototype.send = function () {
    if (!/gif/.test(this.url)) {
      xhrEventTrigger.apply(this, ['xhrOpen', { url: this.url, startTime: this.startTime }]);
      this.addEventListener('readystatechange', function () {
        if (this.readyState === 4) {
          const duration = Date.now() - this.startTime;
          xhrEventTrigger.apply(this, ['xhrFinish', { url: this.url, duration }]);

          if (this.status >= 400 || (/apigw/.test(this.url) && /"ok"\:\s?false/.test(this.responseText))) {
            xhrEventTrigger.apply(this, [
              'xhrError',
              {
                url: this.url,
                duration,
                startTime: this.startTime,
                responseText: this.responseText,
                status: this.status,
                method: this.method
              }
            ]);
          }
        }
      });
    }
    oldSend.apply(this, arguments);
  };
  (XMLHttpRequest as any).prototype.oldOpen = oldOpen;
  (XMLHttpRequest as any).prototype.oldSend = oldSend;
}
