import { getCookie } from '../utils/util';

export interface IUserInfo {
  uid?: string | number;
  isLogin: boolean;
}

let userInfo: IUserInfo = {
  uid: getCookie('user_id') || getCookie('wechat_uid'),
  isLogin: false
};

export function setUserInfo(info: Partial<IUserInfo>) {
  userInfo = {
    ...userInfo,
    ...info
  };
}

export function getUserInfo() {
  return userInfo;
}

export function login(info?: Partial<IUserInfo>) {
  let newUserInfo: Partial<IUserInfo> = { isLogin: true };
  if ((!info || !info.uid)&&!userInfo.uid) {
    newUserInfo.uid = getCookie('user_id') || getCookie('wechat_uid');
  } else {
    newUserInfo = { ...newUserInfo, ...info };
  }
  setUserInfo(newUserInfo);
}

export function logout() {
  setUserInfo({ uid: null, isLogin: false });
}
