import {
  CUSTOM_TOKEN_KEY,
  DEBUG_SERVER_URL,
  SERVER_URL,
  BEAT_SERVER_URL,
  DURATION_SERVER_URL
} from './../constant/index';

import { SEND_TYPE } from '../constant';
// import Base64 from '../utils/base64';
// import { getGlobal } from 'src/utils/util';

export interface IConfig {
  trackKey: string;
  serverUrl: string;
  debugServerUrl: string;
  beatServerUrl: string;
  durationServerUrl: string;
  version: string;
  offlineUrl: string;
  sendType: string;
  delayTime: number;
  autoTrackPage: boolean;
  autoTrackClick: boolean;
  autoInstall: boolean;
  delayLink: boolean;
  delayLinkTime: number;
  deviceIdKey: string;
  beforeGenerateLog: Function | null;
  routeChangeAfter: Function | null;
  autoTrackPrefix: string;
  localGenerateDeviceId: boolean;
  sendBeat: boolean;
}

const ua = window.navigator.userAgent;
const protocol = window.location.protocol === 'http:' ? 'http:' : 'https:';
const host = window.location.host;
let defaultUrl = {};
//跟进host 判断环境
if (/(pre|qa|127|192)\.|localhost/.test(host) || (/env\((pre|qa)\)/i.test(ua) && !host)) {
  defaultUrl = {
    serverUrl: `${protocol}//frontlo-collection.qa.91jkys.com/log_h5.gif`,
    debugServerUrl: `${protocol}//frontlo-collection.qa.91jkys.com/f2e/log.gif`,
    beatServerUrl: `${protocol}//frontlo-collection.qa.91jkys.com/beat.gif`,
    durationServerUrl: `${protocol}//frontlo-collection.qa.91jkys.com/duration.gif`
  };
} else if (/zyhealth\.com/.test(host)) {
  defaultUrl = {
    serverUrl: `${protocol}//frontlo-collection.zyhealth.com/log_h5.gif`,
    debugServerUrl: `${protocol}//frontlo-collection.zyhealth.com/f2e/log.gif`,
    beatServerUrl: `${protocol}//frontlo-collection.zyhealth.com/beat.gif`,
    durationServerUrl: `${protocol}//frontlo-collection.zyhealth.com/duration.gif`
  };
} else if (/dia-solution\.com/.test(host)) {
  defaultUrl = {
    serverUrl: `${protocol}//frontlo-collection.dia-solution.com/log_h5.gif`,
    debugServerUrl: `${protocol}//frontlo-collection.dia-solution.com/f2e/log.gif`,
    beatServerUrl: `${protocol}//frontlo-collection.dia-solution.com/beat.gif`,
    durationServerUrl: `${protocol}//frontlo-collection.dia-solution.com/duration.gif`
  };
} else if (/c4-91jkys\.com/.test(host)) {
  defaultUrl = {
    serverUrl: `${protocol}//frontlo-collection.c4-91jkys.com/log_h5.gif`,
    debugServerUrl: `${protocol}//frontlo-collection.c4-91jkys.com/f2e/log.gif`,
    beatServerUrl: `${protocol}//frontlo-collection.c4-91jkys.com/beat.gif`,
    durationServerUrl: `${protocol}//frontlo-collection.c4-91jkys.com/duration.gif`
  };
} else if (/c10-91jkys\.com/.test(host)) {
  defaultUrl = {
    serverUrl: `${protocol}//frontlo-collection.c10-91jkys.com/log_h5.gif`,
    debugServerUrl: `${protocol}//frontlo-collection.c10-91jkys.com/f2e/log.gif`,
    beatServerUrl: `${protocol}//frontlo-collection.c10-91jkys.com/beat.gif`,
    durationServerUrl: `${protocol}//frontlo-collection.c10-91jkys.com/duration.gif`
  };
}

//default config
let config: IConfig = {
  trackKey: '1f003e7e36e93a0f3954aa466b8acfcf079f3b59d55c258e64113befe292e451', //日志验证
  version: (window as any).version || null,
  offlineUrl: '', // 离线带参数url
  sendType: SEND_TYPE.ASYNC, //发送日志方式 (同步发,异步延迟发,关闭浏览器前发送)
  delayTime: 300, //延迟发送的时间
  autoTrackPage: true, //自动埋点页面
  autoTrackClick: true, //自动埋点a,button,input,attr==='role',e.target
  autoInstall: true, //自动开始埋点监控
  delayLink: false, //跳转延迟
  delayLinkTime: 300,
  deviceIdKey: CUSTOM_TOKEN_KEY,
  beforeGenerateLog: null,
  autoTrackPrefix: '',
  localGenerateDeviceId: true,
  routeChangeAfter: null,
  sendBeat: false,

  serverUrl: SERVER_URL,
  debugServerUrl: DEBUG_SERVER_URL,
  beatServerUrl: BEAT_SERVER_URL,
  durationServerUrl: DURATION_SERVER_URL,
  ...defaultUrl
};

//script tracker-key  config
let scriptDom = document.querySelector('script[track-key]') || document.querySelector('script[trackKey]');
if (scriptDom) {
  let trackKey = scriptDom.getAttribute('track-key') || scriptDom.getAttribute('trackKey') || '';
  if (trackKey) {
    config.trackKey = trackKey;
  }
  let autoTrackPrefix = scriptDom.getAttribute('auto-track-prefix');
  if (autoTrackPrefix) {
    config.autoTrackPrefix = autoTrackPrefix;
  }else if(!config.autoTrackPrefix&&config.trackKey){
    config.autoTrackPrefix=`zhiyun-${config.trackKey.replace(/\d/g,'').substring(0,6)}`
  }
}

export function getConfig() {
  return config;
}

export function setConfig(data: Partial<IConfig>) {
  config = { ...config, ...data };
  if(!config.autoTrackPrefix&&config.trackKey){
     config.autoTrackPrefix=`zhiyun-${config.trackKey.replace(/\d/g,'').substring(0,6)}`
  }
}
